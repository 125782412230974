import React, { useState, useEffect } from 'react'
import { graphql } from 'gatsby'
import SEO from '../components/seo'
import Layout from '../components/layout'
import BannerImpact from '../components/banner-impact'

const VideoTemplate = ({ pageContext, data }) => {
  const { allYoutubePlaylistItems, allYoutubePlaylists } = data
  const { playlistId } = pageContext
  const [playVideo, setPlayVideo] = useState({
    id: '',
    title: 'Loading...',
    description: '',
  })

  const [videoPlaylistItems, setVideoPlaylistItems] = useState([])
  const [videoPageContents, setVideoPageContents] = useState({
    title: 'Loading...',
    desc: '',
  })

  const loadVideoList = () => {
    setVideoPlaylistItems(allYoutubePlaylistItems.edges)
    const id = allYoutubePlaylistItems.edges[0].node.snippet.resourceId.videoId
    const { title } = allYoutubePlaylistItems.edges[0].node.snippet
    const desc = allYoutubePlaylistItems.edges[0].node.snippet.description
    setPlayVideo({
      id,
      title,
      description: desc,
    })
    const videoData = allYoutubePlaylists.nodes.find(
      list => playlistId === list.id
    )
    setVideoPageContents({
      title: videoData.snippet.title,
      desc: videoData.snippet.description,
    })
  }

  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    loadVideoList()
  }, [])

  const handleClick = (e, video) => {
    const id = video.snippet.resourceId.videoId
    const { title } = video.snippet
    const desc = video.snippet.description
    setPlayVideo({
      id,
      title,
      description: desc,
    })
  }

  const patt = /(http|https):\/\/([\w_-]+(?:(?:\.[\w_-]+)+))([\w.,@?^=%&:/~+#-]*[\w@?^=%&/~+#-])?/gi

  const replaceAnyURL = string =>
    string.replace(
      patt,
      '<a href="$&" target="_blank" rel="noopener noreferrer" className="btn btn-link">$&</a>'
    ) // $& means the whole matched string

  const MainVideo = ({ id, title, description }) => (
    <div className="col-md-9 mt-5">
      <div className="video-series-into shadow">
        <div className="embed-responsive embed-responsive-16by9">
          <iframe
            className="embed-responsive-item"
            src={`https://www.youtube.com/embed/${id}?autoplay=1`}
            title="Main Video"
            rameborder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </div>
      </div>
      <PaginationVideos currentVideo={playVideo} />
      <h3 className="h5 font-weight-bold mt-3">{title}</h3>
      <p
        dangerouslySetInnerHTML={{
          __html: replaceAnyURL(description).replace(/\n/g, '<br />'),
        }}
      />
    </div>
  )
  const PaginationVideos = ({ currentVideo }) => (
    <div className="d-flex flex-wrap video-pagination border-bottom py-2 py-lg-3">
      <p className="m-0 my-auto">Episodes:</p>
      {videoPlaylistItems.map(({ previous, node: video, next }, index) => {
        const id = video.snippet.resourceId.videoId
        const videoNum = index + 1 // index === 0 ?'Previous': (index === videoPlaylistItems.length-1? 'Next': index+1)
        return currentVideo.id === id ? (
          <button
            className="btn video-item text-white bg-green border rounded-circle p-0 my-1 my-lg-0 mx-1 mx-lg-2 active"
            onClick={e => handleClick(e, video)}
            key={index}
          >
            {videoNum}
          </button>
        ) : (
          <button
            className="btn video-item text-white border rounded-circle p-0 my-1 my-lg-0 mx-1 mx-lg-2"
            onClick={e => handleClick(e, video)}
            key={index}
          >
            {videoNum}
          </button>
        )
      })}
    </div>
  )
  const Video = ({ title, thumbnail, onClick }) => (
    <div className="col-md-6 col-lg-3 mt-5">
      <div className="video-series-box mx-auto mx-md-0">
        <a href="#top" className="video-series-thum" onClick={onClick}>
          <img
            src={thumbnail.standard.url}
            alt={title}
            className="img-fluid video-series-img"
            height={thumbnail.standard.height}
          />
        </a>
        <h4 className="h6 font-weight-bold mt-3">{title}</h4>
      </div>
    </div>
  )

  return (
    <>
      <div id="top" />
      <Layout>
        <SEO title="Video Series" />
        <section className="bg-dark text-white py-5">
          <div className="container">
            <h1 className="text-center font-weight-bold mb-3">
              {videoPageContents.title}
            </h1>
            <div className="row">
              <MainVideo
                id={playVideo.id}
                title={playVideo.title}
                description={playVideo.description}
              />
              <div className="col-md-3 mt-5">
                <p>{videoPageContents.description}</p>
              </div>
            </div>
            <ul className="nav nav-tabs nav-video-tabs mt-5" id="myTab">
              <li className="nav-item">
                <a
                  className="nav-link active"
                  id="episodes-tab"
                  data-toggle="tab"
                  href="#episodes"
                  role="tab"
                  aria-controls="episodes"
                  aria-selected="true"
                >
                  EPISODES
                </a>
              </li>
              {/*
                    <li className="nav-item">
                        <a className="nav-link" id="like-tab" data-toggle="tab" href="#like" role="tab" aria-controls="like" aria-selected="false">MORE LIKE THIS</a>
                    </li>
                    
                    <li className="nav-item">
                        <a className="nav-link" id="review-tab" data-toggle="tab" href="#review" role="tab" aria-controls="review" aria-selected="false">REVIEW</a>
                    </li>
                    */}
            </ul>
            <div className="tab-content" id="myTabContent">
              <div
                className="tab-pane fade show active"
                id="episodes"
                role="tabpanel"
                aria-labelledby="episodes-tab"
              >
                <div className="row">
                  {videoPlaylistItems.map(({ node: video }, i) => {
                    const id = video.snippet.resourceId.videoId
                    const { title } = video.snippet
                    const thum = video.snippet.thumbnails
                    return (
                      <Video
                        videoId={id}
                        title={title}
                        thumbnail={thum}
                        onClick={e => handleClick(e, video)}
                        key={i}
                      />
                    )
                  })}
                </div>
                {/*
                        <hr className="hr bg-green mt-5 mb-3" />
                        <h4 className="">MORE LIKE THIS</h4>
                        <div className="row">
                            <div className="col-md-6 col-lg mt-5">
                                <div className="video-series-box mx-auto mx-md-0">
                                    <div className="video-series-thum">
                                        <img src={`https://img.youtube.com/vi/8QUIHRakeHo/0.jpg`} alt="" className="img-fluid" />
                                    </div>
                                    <h4 className="h6 font-weight-bold text-green mt-3">DAVID GOULET</h4>
                                    <h5 className="h6 font-weight-bold">THE BREATH OF LIFE</h5>
                                </div>                                                           
                            </div>   
                            <div className="col-md-6 col-lg mt-5">
                                <div className="video-series-box mx-auto mx-md-0">
                                    <div className="video-series-thum">
                                        <img src={`https://img.youtube.com/vi/8QUIHRakeHo/0.jpg`} alt="" className="img-fluid" />
                                    </div>
                                    <h4 className="h6 font-weight-bold text-green mt-3">COLLABORATOR</h4>
                                    <h5 className="h6 font-weight-bold">SERIES NAME</h5>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg mt-5"> 
                                <div className="video-series-box mx-auto mx-md-0">
                                    <div className="video-series-thum">
                                        <img src={`https://img.youtube.com/vi/8QUIHRakeHo/0.jpg`} alt="" className="img-fluid" />
                                    </div>
                                    <h4 className="h6 font-weight-bold text-green mt-3">COLLABORATOR</h4>
                                    <h5 className="h6 font-weight-bold">SERIES NAME</h5>
                                </div>     
                            </div>   
                       </div>
                       */}
              </div>
              {/*
                            <div className="tab-pane fade" id="like" role="tabpanel" aria-labelledby="like-tab">...</div>
                            <div className="tab-pane fade" id="review" role="tabpanel" aria-labelledby="review-tab">...</div>
                        */}
            </div>
          </div>
        </section>
        <BannerImpact />
      </Layout>
    </>
  )
}
export default VideoTemplate
export const query = graphql`
  query($playlistId: String!) {
    allYoutubePlaylistItems(
      filter: { snippet: { playlistId: { eq: $playlistId } } }
    ) {
      edges {
        node {
          snippet {
            title
            thumbnails {
              standard {
                url
                width
                height
              }
            }
            description
            resourceId {
              videoId
            }
            playlistId
          }
        }
        next {
          snippet {
            playlistId
          }
        }
        previous {
          snippet {
            playlistId
          }
        }
      }
    }
    allYoutubePlaylists {
      nodes {
        snippet {
          title
          description
        }
        id
      }
    }
  }
`
